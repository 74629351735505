@import 'styles/config';

.ResourcesCard {
    box-shadow: none;
    border-radius: 11px;
    margin: 16px 11px 0 11px;

    &__body {
        padding: 40px;
    }
}

.Card{
    background-color: white;
    padding: 5px;
}

.CreateResource {
    &__input {
        box-shadow: none !important;

        &:active,
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    }

    &__checkbox {
        &:hover {
            box-sizing: none;
        }
    }
}

.FileControl {
    &__attachment-list {
        margin-top: $spacer;
    }

    &__button-icon {
        height: 18.81px;
        width: 20.97px;
        object-fit: contain;
        margin-bottom: 12px;
        margin-top: 27px;
    }

    &__button-p-text {
        font-size: 12px;
        line-height: 16px;
        color: $calendar-header-black;
    }

    &__button {
        border: 1px dashed $gray-400 !important;
        background: $white !important;
        color: $text-muted !important;
        width: 100px !important;
        min-height: 100px;
        font-size: 0.875rem;
        padding: 0;
        margin-right: $spacer !important;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;

        @include hover-focus() {
            background: $gray-100 !important;
            color: $body-color !important;
            border-color: $text-muted !important;
        }

        &:active {
            background: $gray-200 !important;
            border-color: $text-muted !important;
        }

        &:disabled {
            background: $gray-100 !important;
            color: $text-muted !important;
            border-color: $gray-300 !important;
        }
    }
}

.file_text_input_group {
    display: flex;
    align-items: center;

    .file_input_group {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 992px) {
        flex-direction: column;
        .file_input_group {
            margin-bottom: 30px;
        }
    }
}

@import 'styles/config';

.AdminAddArticle {
  &__body {
    padding: 40px;
  }

  &__card {
    border-radius: 0.5rem;
    box-shadow: none;
  }

  &__form-title {
    color: $calendar-blue;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }

  &__form-input {
    font-size: 14px;
    border: 1px solid $gray-E9;
    padding: 22px 17px;
    height: 66px;
    box-shadow: none;

    &:focus {
      box-shadow: none;
      border: 1px solid $gray-E9;
    }

    &--group {
      display: flex;
      align-items: center;
    }
  }

  &__delete-icon {
    cursor: pointer;

    path {
      fill: $calendar-red;
    }
  }

  &__FileDropzone {
    width: 100%;
    height: 150px;

    &--childeren {
      display: flex;
      flex-direction: row;
    }

    &--image {
      margin-right: 1rem;
    }

    &:hover {
      color: #495057;
    }
  }

  &__Accordion {
    overflow: auto;
    transition: max-height 0.6s ease;

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    /* Optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  &__RotateIcon {
    transition: transform 0.6s ease;

    &--not-active {
      transform: rotate(180deg);
    }
  }
}

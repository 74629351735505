@import 'styles/config';

.ProfileDetailsCard {
  &__image-col {
    border-right: 1px solid $gray-E9;
  }

  &__details-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__field-label {
    font-size: 0.875rem;
    margin-bottom: 0.25rem !important;
    font-weight: 600;
    color: $calendar-header-black;
  }

  &__field-value {
    font-size: 1rem;
    color: $calendar-black;
    margin-bottom: $spacer;
  }
}

.profile-picture {
  width: $spacer * 3.75;
  height: $spacer * 3.75;
  max-width: 100%;
}

.profile-picture--placeholder {
  display: inline-block;
  background-color: #ddd;
}

.profile-picture--large {
  width: $spacer * 12;
  height: $spacer * 12;
}

.profile-picture--round {
  border-radius: 50%;
}

// .--page {
//   margin-left: 1rem;
// }

@import 'styles/config';

.TableDropdown {
  &__icon {
    color: $gray-550;
    height: 21px !important;
    width: 21px !important;
  }

  &__toggle {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background: white !important;
    color: black !important;
    display: flex;
    border: none;
    align-items: center;
    box-shadow: none;

    &:active {
      background: white !important;
      color: black;
      box-shadow: none !important;

      .setting_icon_table {
        color: $calendar-blue;
      }
    }

    &:hover {
      background: white !important;
      color: black;
      box-shadow: none !important;
    }

    &:focus {
      background: white !important;
      box-shadow: none !important;

      .setting_icon_table {
        color: $calendar-blue;
      }
    }
  }

  &__dropdown-menu {
    border: none;
    border-radius: 11px;
    background: $white;

    // padding: 19px 23px;
    &:hover {
      background: $white;
    }

    a {
      padding: 12px 23px;

      &:active {
        background: $white !important;
      }
    }
  }
}

.TableDropdownItem {
  display: flex !important;
  align-items: center !important;

  &__icon {
    width: 1rem;
  }

  &__title {
    font-size: 14px;
    color: $calendar-header-black;
    line-height: 21px;
    font-family: $font-family-sans-serif;
    margin-left: 10px;
  }
}

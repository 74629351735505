@import 'styles/config';

$DashboardSidebar__active-color: $primary;
$DashboardSidebar__hover-bg: transparentize($DashboardSidebar__active-color, 0.95);
$DashboardSidebar__active-bg: transparentize($DashboardSidebar__active-color, 0.9);
$DashboardSidebar__click-bg: transparentize($DashboardSidebar__active-color, 0.85);

$DashboardSidebarLink__spacer: $spacer;
$DashboardSidebarLink__icon-size: 1.25rem; //20px
$DashboardSidebarLink__icon-spacer: $spacer * .5;

.DashboardSidebarLink {
  text-decoration: none;
  color: $text-muted;
  font-size: $font-size-base * 1.125;
  margin-bottom: $DashboardSidebarLink__spacer;
  transition: background .5s, font-size .5s;
  overflow: hidden;
  align-items: center;
  max-width: 100%;

  display: flex !important; // important: overwrite .nav-link style
  padding: $spacer $nav-link-padding-x * 1.7 !important; // important: overwrite .nav-link style

  &--border {
    border-left: 1px solid #e9ecef;
  }

  @include hover-focus() {
    text-decoration: none;
    background: $DashboardSidebar__hover-bg !important;
    color: $DashboardSidebar__active-color;
  }

  &.active {
    text-decoration: none;
    background: $DashboardSidebar__active-bg !important;
    color: $DashboardSidebar__active-color;
    border-left: $calendar-blue 2px solid;
  }

  &:active {
    background: $DashboardSidebar__click-bg !important;
  }

  &--sm {
    font-weight: 500;
    font-size: $font-size-base;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: $DashboardSidebarLink__spacer;
    }
  }

  &__icon {
    min-width: $DashboardSidebarLink__icon-size;
    min-height: $DashboardSidebarLink__icon-size;
    margin-left: $DashboardSidebarLink__icon-spacer;
    transition: all .5s;

    &--collapsed {
      min-width: 18px;
      min-height: 18px;
    }
  }

  &--collapsed {
    padding-left: 1px;
  }

  &__title {
    margin-left: $spacer;
    transition: all .5s;
    white-space: nowrap;

    &--sm {
      padding-left: $DashboardSidebarLink__icon-size + $DashboardSidebarLink__icon-spacer;
    }

    &--collapsed {
      margin-left: $spacer * 5;
    }
  }
}

.navbar {
  font-family: $font-family-headings;

  @include media-breakpoint-up(lg) {
    height: 14.75rem;
  }
}

.navbar-brand__logo {
  height: 24px;

  @include media-breakpoint-up(lg) {
    height: 48px;
  }
}

.nav-item--divider {
  width: 4px;
  background: $black;
}

.nav-item--has-button {
  @include media-breakpoint-up(lg) {
    padding-left: $navbar-nav-link-padding-x;
    padding-right: $navbar-nav-link-padding-x;
  }
}

.navbar__nav-items {
  flex-basis: 0% !important;
  flex-grow: 1 !important;
}

.navbar__nav-items--collapse {
  flex-basis: 100% !important;
  @include media-breakpoint-up(lg) {
    flex-basis: 0% !important;
  }
}

.navbar__toggler-wrapper {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.navbar__featured-btn {
  @include media-breakpoint-up(lg) {
    @include button-size($btn-featured-padding-y, $btn-featured-padding-x, $input-btn-font-size, $input-btn-line-height, $input-border-radius);
  }
}

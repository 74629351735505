@import 'styles/config';

.CreateIssue__title {
    margin-top: 5rem;
    margin-bottom: 2.75rem;
}

.CreateIssue__input {
    width: 100%;
    border-style: solid;
    border-color: #02c3ee;
    border-radius: 0px;
}

.CreateIssue__description {
    height: 300px;
    border-style: solid;
    border-color: #02c3ee;
    border-radius: 0px;
}

.CreateIssue__btn {
    border-radius: 0;
    width: 150px;
}

.description_height {
    height: calc(100% - 47px) !important;
    border: #e9e9e9 1px solid !important;
}

.CreateIssue {
    padding: 20px 5px 22px 5px;
}

.form_cc_title_input {
    padding: 1.5rem;
    font-size: 14px;
    box-shadow: none;
    border: #e9e9e9 1px solid !important;
    &:focus {
        box-shadow: none;
    }

    &:hover {
        text-decoration: none !important;
    }
}
.title_input {
    padding: 1.5rem;
    border: #e9e9e9 1px solid !important;
}

html {
  height: 100%; /* fallback for IE and older browsers */
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.browser-height {
    height: auto; /* required to allow content to expand vertically without overflow */
    width: auto;
    min-height: 100%; /* fallback for IE and older browsers */
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

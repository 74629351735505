@import '../../../styles/config';

$Dashboard__sidebar-width: 18.75rem;
$Dashboard__sidebar-width-collapsed: 5.7rem;

.Dashboard {
  position: relative;
  background: $gray-200;

  &__content {
    margin-left: $Dashboard__sidebar-width;
    transition: all 0.5s;

    &--collapsed {
      margin-left: $Dashboard__sidebar-width-collapsed;
    }
  }

  &__aside {
    position: fixed;
    width: $Dashboard__sidebar-width;
    height: 100%;
    transition: all 0.5s;
    background: $white;
    @include box-shadow(0 0 0.75rem transparentize($gray-800, 0.9));

    &--collapsed {
      width: $Dashboard__sidebar-width-collapsed;
    }
  }

  &__container {
    padding-top: $spacer * .5;
    padding-bottom: 47px;
  }

  &--slide-in {
    animation: dashboard-slide-in 0.5s forwards;
    -webkit-animation: dashboard-slide-in 0.5s forwards;
  }

  &--slide-out {
    animation: dashboard-slide-out 0.5s forwards;
    -webkit-animation: dashboard-slide-out 0.5s forwards;
  }

  @keyframes dashboard-slide-in {
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes dashboard-slide-in {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes dashboard-slide-out {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @-webkit-keyframes dashboard-slide-out {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}


@import 'styles/config';

$ResourceKindControl__border-active-color: $calendar-header-black;
$ResourceKindControl__hover-color: $gray-700;
$ResourceKindControl__height: 2.1875rem;

.ResourceKindControl {
  font-size: 19px;

  &__link {
    height: $ResourceKindControl__height;
    line-height: $ResourceKindControl__height;
    border: 1px solid $ResourceKindControl__border-active-color;
    text-decoration: none;
    padding: 0 1.25rem;
    border-radius: 3.125rem;
    font-size: 0.875rem;
    color: $ResourceKindControl__border-active-color;
    margin: 0.3125rem;

    &:hover {
      text-decoration: none;
      color: $ResourceKindControl__hover-color;
    }

    &--active {
      background-color: $ResourceKindControl__border-active-color;
      color: white !important;
    }
  }
}

.page-hub {

}

.page-hub__profile-name {
  display: block;
  font-family: $headings-font-family;
  font-size: 2rem;
}

// Variation for .stretched-link which disables text decoration
.stretched-link--nodec:hover {
  text-decoration: none;
}
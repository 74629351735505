@import 'styles/config';

.NotFound {
  padding: 95px 0 $spacer * 10;
  text-align: center;

  &__image {
    width: 900px;
    max-width: 100%;
  }

  &__title {
    margin-bottom: 23px;
    font-size: 40px;
  }

  &__description {
    font-size: 20px;
    color: $calendar-header-black;
  }

  &__button {
    width: 186px;
    height: 50px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white !important;
    text-decoration: none !Important;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 3.125rem;
  }
}

.modal-centered {
  width: 100vh !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-md {
  min-width: 675px !important;

  @media screen and (max-width: 600px) {
    min-width: 400px !important;
  }
}

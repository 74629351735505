.bg-cyan {
  background: $cyan;
  color: $white;
}

.background-color {
  &__gradient {
    background: linear-gradient(180deg,
      transparent 0%,
      rgba(#ebebeb, 0) 60%,
    rgba(222, 222, 222, 0) 95%,
    #d8d8d8 118%)
  }
}

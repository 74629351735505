@import 'styles/config';

.FileControl {
    &__attachment-list {
        margin-top: $spacer;
    }

    &__button-icon {
        height: 18.81px;
        width: 20.97px;
        object-fit: contain;
        margin-bottom: 12px;
        margin-top: 27px;
    }
    &__button-p-text {
        font-size: 12px;
        line-height: 16px;
        color: $calendar-header-black;
    }

    &__button {
        border: 1px dashed $gray-400;
        background: $white;
        color: $text-muted;
        width: 100px;
        min-height: 100px;
        font-size: 0.875rem;
        padding: 0;
        margin-right: $spacer;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        @include hover-focus() {
            background: $gray-100 !important;
            color: $body-color !important;
            border-color: $text-muted !important;
        }

        &:active {
            background: $gray-200 !important;
            border-color: $text-muted !important;
        }

        &:disabled {
            background: $gray-100 !important;
            color: $text-muted !important;
            border-color: $gray-300 !important;
        }
    }
}

.file_text_input_group {
    display: flex;
    align-items: center;
    .file_input_group {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 992px) {
        flex-direction: column;
        .file_input_group {
            margin-bottom: 30px;
        }
    }
}

.spinnerBox{
    margin-left: 2px;
border: solid 1px $gray-300;
display: flex;
flex-direction: row;
// padding: 10px;
padding-top: 10px;
padding-right: 5px;
padding-left: 5px;
padding-bottom: 10px;
border-radius: 5px;
margin-bottom: 10px;
width: 400px;
}

.LegalMatter {
    &__UploadImg {
        &--container {
            display: flex;
            width: 100%;
            margin-bottom: 8px;
            margin-top: 8px;
        }
        &--left {
            width: 40px;
        }
        &--center {
            width: calc(100% - 60px);
        }
        &--right {
            width: 20px;
            cursor: pointer;
        }
        &--Cancel-icon {
            transform: scale(1.5);
        }
        &--text {
            font-size: 10px;
            font-family: $font-family-sub-headings;
            line-height: 16px;
        }
        &--ProgressBar {
            margin-top: 7px;
            .progress {
                background-color: $gray-E9;
                .progress-bar {
                    width: 0%;
                    height: 3px;
                    background-color: $calendar-blue !important;
                }
            }
            .progress {
                background-color: $gray-E9;
                .progress-bar-complete {
                    width: 80%;
                    transition: all 0.7s;
                    height: 3px;
                    background-color: $calendar-blue !important;
                    animation-name: ProgressBarAnimation;
                    animation: ProgressBarAnimation 1s;
                }
            }
            &--active {
                .progress {
                    .progress-bar {
                        width: 100%;
                        transition: all 0.7s;
                        background-color: $calendar-light-green !important;
                    }
                }
            }
        }
    }
}

.colsize{
    &--left{
        width: 10%;
    }
    &--center{
        width: 80%;

    }
    &--right{
        width: 10%;
    }

}

.bunFileControl{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chooseLink{
    font-size: 14px;
    color: #0990F2;
    width: 200px;
    text-align: center;
    cursor: pointer;
}

.PostView__type {
  text-align: center;
  margin: 5rem 0;
}

.PostView__title {
  text-align: center;
  vertical-align: middle;
}

.PostView__tag-wrapper {
  width: 100%;
  text-align: right;
}

.PostView__back-btn-wrapper {
  padding: 1rem;
}

.PostView__content {
  margin: 1rem;
}

.PostView__author {
  text-align: center;
  font-size: 90%;
}

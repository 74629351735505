@import "styles/config";

.FileDropzone {

  &__dropzone {
    border: 1px dashed $gray-400;
    padding: $spacer;
    transition: all .2s;
    text-align: center;
    color: $gray-700;

    &--small {
      padding: $spacer * .5;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      border-color: $primary;
    }

    &:hover,
    &--active {
      color: $primary;
    }
  }

  &__pending-item {
    margin-bottom: $spacer * .5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__file-list {
    margin-left: $spacer;
  }
}

@import 'styles/config';

.PaginationControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1.875rem 0;

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__page-number {
    padding: 0 10px;
    font-size: 15px;
    font-family: $font-family-sub-headings;
    line-height: 20px;
  }

  &__link {
    color: $calendar-black;
    padding: 0 10px;
    text-decoration: none;

    @include hover-focus-active() {
      color: $calendar-black;
      text-decoration: none;
    }

    &--disabled {
      color: $calendar-header-black;
      cursor: default;

      @include hover-focus-active() {
        color: $calendar-header-black;
        text-decoration: none;
      }
    }
  }
}

@import 'styles/config';

.ProfileImageList {
  &__image {
    cursor: pointer;
    margin-bottom: $spacer;

    &--selected {
      border: 2px solid $primary;
    }
  }
}


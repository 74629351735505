@import 'styles/config';

.FormCard {
  &__title {
    font-size: 1.125rem !important;
    font-weight: 800;
    margin-top: 2.5rem;
    margin-bottom: 0.9375rem;
  }
}

@import 'styles/config';

.ResumeController {
  &__file--input {
    display: none;
  }

  &__resume {
    &--upload {
      padding: 16px;
      width: 100%;
      border: 1px dashed $gray-E9;
      border-radius: 15px;

      .FileDropzone__content {
        width: 100%;
      }

      &--flex-center {
        padding: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &--right {
        width: 25px;
        text-align: center;
        color: $calendar-red;
      }

      &--right--center {
        width: 40px;
        color: $calendar-header-black;

        svg {
          path {
            fill: $calendar-header-black;
          }
        }
      }

      &--left--center {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--left {
        width: 25px;
        color: $calendar-red;
      }
    }

    &--place-holder {
      font-size: 1rem;
      line-height: 19px;
      color: $calendar-header-black;
    }
  }
}

@import 'styles/config';

$DashboardSidebar__active-color: $primary;
$DashboardSidebar__hover-bg: transparentize($DashboardSidebar__active-color, 0.95);
$DashboardSidebar__active-bg: transparentize($DashboardSidebar__active-color, 0.9);
$DashboardSidebar__click-bg: transparentize($DashboardSidebar__active-color, 0.85);

.DashboardSidebar {
  &__header {
    display: block;
    padding: 1rem;
    margin-bottom: $spacer;
    border-bottom: 1px solid $gray-200;
    overflow: hidden;
  }

  &__header-image {
    height: 54px;
  }

  &__footer {
    border-top: 1px solid $gray-200;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}

.btn-lg {
    border-radius: 2.5rem;
    font-weight: bold;
}

.btn--outline-subtle {
    @include button-outline-variant($gray-600);
}

.small_padding {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.btn-featured-inverted {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );

    color: #0990f2;
    background: white;
    border: solid;
    border-width: 0.5px;
    border-color: #0990f2;

    font-family: $font-family-headings;

    //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: #0990f2;
        // background: rgb(228, 227, 227);
    }

    &:focus,
    &.focus,
    &:active {
        color: #0990f2;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: #0990f2;
        // background-color: $btn-featured-bg-hover;
        //background-image: none; // Remove the gradient for the pressed/active state
        //border-color: transparent;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            //box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
        }
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}
.btn-featured-primary {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );

    color: white;
    background: $calendar-blue;
    border: solid;
    border-width: 0.5px;
    border-color: $calendar-blue;

    font-family: $font-family-headings;
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: white;
        background: $calendar-blue;
        border-color: $calendar-blue;
    }

    &:focus,
    &.focus,
    &:active {
        color: white;
        background: $calendar-blue !important;
        border-color: $calendar-blue !important;
        box-shadow: none !important;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}
.btn-featured-green {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );

    color: white;
    background: $calendar-light-green;
    border: solid;
    border-width: 0.5px;
    border-color: $calendar-light-green;

    font-family: $font-family-headings;
    &--normal-text{
        font-family: $font-family-sans-serif;
    }
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: white;
        background: $calendar-light-green;
        border-color: $calendar-light-green;
    }

    &:focus,
    &.focus,
    &:active {
        color: white;
        background: $calendar-light-green !important;
        border-color: $calendar-light-green !important;
        box-shadow: none !important;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn-featured-red {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );

    color: white;
    background: $calendar-red;
    border-color: $calendar-red;
    text-decoration: none !important;

    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: white;
        background: $calendar-red;
        border-color: $calendar-red;
    }

    &:focus,
    &.focus,
    &:active,
    &:focus-visible,
    &:focus-within {
        color: white;
        background: $calendar-red !important;
        border-color: $calendar-red !important;
        box-shadow: none !important;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn-featured-blue {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );

    color: white;
    background: $calendar-blue;
    border-color: $calendar-blue;
    text-decoration: none !important;
    box-shadow: none !important;
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: white;
        background: $calendar-blue;
        border-color: $calendar-blue;
    }

    &:focus,
    &.focus,
    &:active,
    &:focus-visible,
    &:focus-within {
        color: white;
        background: $calendar-blue !important;
        border-color: $calendar-blue !important;
        box-shadow: none !important;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn--without-background {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $input-border-radius
    );

    color: $gray-800;
    border: none;
    background-color: transparent !important;
    box-shadow: none;
    @include hover() {
        color: $gray-800;
    }

    &:focus,
    &.focus,
    &:active {
        color: $gray-800;
        outline: none;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $gray-800;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn-featured {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $btn-border-radius
    );
    //@include button-size($btn-featured-padding-y, $btn-featured-padding-x, $input-btn-font-size, $input-btn-line-height, $input-border-radius);

    //Shaded
    border: hidden;
    color: $white;
    //background: $btn-featured-bg-hover;

    font-family: $font-family-headings;

    //color: $black;
    background: $btn-featured-bg;
    //border: solid;
    //border-width: 0.5px;
    //border-color: #66ccff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: white;
        background: $btn-featured-bg-hover;
    }

    &:focus,
    &.focus,
    &:active {
        color: $white;
        box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $white;
        background-color: $btn-featured-bg-hover;
        //background-image: none; // Remove the gradient for the pressed/active state
        //border-color: transparent;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            //box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
        }
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn-featured-transparent {
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $input-btn-font-size,
        $input-btn-line-height,
        $input-border-radius
    );

    color: $gray-800;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: $gray-800;
    }

    &:focus,
    &.focus {
        color: $gray-800;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $gray-800;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn-reschedule {
    @include button-size($btn-padding-y, 0.5rem, $input-btn-font-size, $input-btn-line-height, $input-border-radius);

    color: $white;
    background: $btn-reschedule-bg;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    background-clip: padding-box; // fixes linear-gradient bleeding with border radius

    @include hover() {
        color: $white;
        background-color: $btn-reschedule-bg-hover;
    }

    &:focus,
    &.focus {
        color: $gray-800;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $white;
        background-color: $btn-reschedule-bg-hover;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-border-radius {
        border-radius: 0;
    }
}

.btn--shadow {
    transition: all 0.15s ease-in-out;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.16);

    &:hover {
        transform: scale(1.05, 1.05);
    }

    &:after {
        content: '';
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2);
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover:after {
        opacity: 1;
    }
}

.btn-animation {
    min-width: 300px;
    color: #ffffff;
    background: #01c8ee;
    background: linear-gradient(90deg, #e601ee 0%, #f20a29 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
}

.btn-animation::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    border: 6px solid #f20a29;
    box-shadow: 0 0 60px #e601ee;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
}

.btn-animation::after {
    content: '';
    width: 30px;
    border-radius: 100%;
    border: 6px solid #f20a29;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
}

@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}

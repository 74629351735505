@import 'styles/config';

.PageError {
  font-size: 1.5rem;
  text-align: center;
  color: $danger;
  padding: $spacer * 2 0;

  &__image {
    margin-bottom: $spacer * 3;
    max-width: 100%;
  }
}

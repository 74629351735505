.ColorSelector {

  &__control {
    display: flex;
    flex-wrap: wrap;
  }

  &__item-label {
    cursor: pointer;
    width: 100%;
    padding: 10px;

    &--disabled {
      opacity: .75;
      cursor: default;
    }
  }

  &__item-badge {
    min-width: 2rem;
    min-height: 2rem;
    width: 2rem;
    height: 2rem;
    transition: all 0.3s;

    &.badge:empty {
      display: inline-block;
    }

    &:hover {
      transform: scale(1.2);
    }

    &--disabled {
      &:hover {
        transform: none;
      }
    }

    &--active {
      transform: scale(1.2) !important;
      box-shadow: 0 6px 10px rgba(0, 0, 0, .2);
    }
  }

  &__item-check-icon {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    right: 0;
    top: 0;
  }
}

@import '../../../../styles/config';

$main-navbar__spacer-sm: $spacer;
$main-navbar__spacer: $main-navbar__spacer-sm * 2;

.AppNavbar {
  padding: 1.2rem 0;

  &__icon {
    cursor: pointer;
    padding: 8px 4px 8px 16px;

    &--link {
      text-decoration: none !important;
      color: black;

      img {
        width: 22px;
        height: 22px;
      }

      &:active {
        text-decoration: none;
        color: black;

      }

      &:hover {
        text-decoration: none;
        color: black;

      }
    }
  }

  &__dropdown-toggle {
    display: block;
  }

  &__aside {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: $gray-900;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;

    &--open {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &--close {
      display: none;
    }
  }

  &__aside-close {
    color: $gray-400;
    padding: $spacer;
    font-size: 1.5rem;
    align-self: flex-end;

    &:hover {
      color: white;
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8);

    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }

  &__aside-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }

  &__nav-items {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__nav-item {
    padding: 0 $main-navbar__spacer;

    &--link {
      margin: 0 $main-navbar__spacer;
      padding: 0.5rem;
    }
  }

  &__nav-item--right {
    padding: 0 2rem;
  }

  &__link {
    color: $text-muted;
    text-transform: uppercase;
    font-weight: 500;
    font-family: $font-family-sub-headings;

    &--active {
      border-bottom: 2px solid #0990f2;
      padding: 0.5rem;
      color: #0990f2;

      &:hover {
        color: #0990f2 !important;
        text-decoration: none !important;
      }
    }

    &:hover {
      color: $text-muted;
      text-decoration: underline;
    }
  }

  &__brand {
    height: 40px;
    object-fit: contain;
    width: 100%;
  }

  &__dropdown-name {
    text-transform: none;
    font-size: 16px;
    color: #151c2d;
  }
}


.NavDropdown {
  width: 215px;
  border: 0 !important;
  border-radius: 10px !important;

  &__link {
    font-size: 14px !important;
    color: #788b9a !important;
    padding-bottom: 11px !important;
    padding-top: 11px !important;
  }

  &__icon {
    margin-right: 11px;
  }
}

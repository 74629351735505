@import '../../../styles/config';

.AdminResources__id-col {
  width: 12.5rem;
  font-size: $font-size-sm;
}

.cursor-pointer {
  cursor: pointer;
}

.tableData {
  &--id {
    width: 12%;
  }

  &--title {
    width: 12%;
    overflow: hidden;
    height: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      width: 12%;
      overflow: hidden;
      white-space: nowrap;
      height: 10px;
      text-overflow: ellipsis;
    }
  }
}

.tableRow {
  &--50 {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--10 {
    width: 10%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--20 {
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--30 {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--40 {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--100 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// badge colors
// bootstrap creates variants for all `$theme-colors` values, we just need to add them for `$colors`

@each $color, $value in $colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

.badge-feature {
  position: relative;
}

.badge-feature[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  padding: $spacer / 5 $spacer / 4;
  top: -20px;
  right: -20px;
  font-size: 0.7em;
  background: $danger;
  color: $white;
  width: auto;
  text-align: center;
  line-height: 18px;
  border-radius: 40px;
}

.badge--lg {
  padding: 11px 22px !important;
  border-radius: 20px;
  margin-right: 6px;
}

.badge {
  &--primary-muted {
    background-color: #DDEEFB;
    color: #0990F2;
  }

  &--success-muted {
    background-color: #E0F9F6;
    color: #44CEBB;
  }

  &--light-warning-muted {
    background-color: #F9F4EF;
    color: #F8B471;
  }

  &--warning-muted {
    background-color: #F8B471;
    color: white;
  }

  &--danger-muted {
    background-color: #ffeeee;
    color: #F86060;
  }
}

.TableSearch.form-control {
  width: 350px;
  height: 50px;
  border: none;
  background-color: white;
  background-position-y: center !important;
  background-position-x: 21px !important;
  border-radius: 33px;
  padding-left: 52px;
  font-size: 14px;
}

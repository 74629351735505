@import 'styles/config';

.TagSelector {
    position: relative;

    &__control {
        background: $white;
        border: 1px solid $gray-E9;
        padding: 16px 23px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__selected-tags {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    &__caret {
        cursor: pointer;
        margin-left: 44px;

        path {
            fill: $calendar-header-black;
        }
    }
    &__checkbox {
        .custom-control-label {
            &::after,
            &::before {
                top: 0.15rem !important;
            }
        }
    }

    &__dropdown-menu {
        padding: 16px 23px;
        border: 1px solid $gray-E9;
        background-color: white;
        position: absolute;
        z-index: 10;
        height: 17rem;
        overflow-y: scroll;
    }

    &__dropdown-column {
        display: flex;
        align-items: center !important;
        margin-bottom: 1.5625rem;
    }

    &__dropdown-tag-label {
        margin-left: $spacer;
        margin-bottom: 0;
        cursor: pointer;
    }

    &__badge {
        display: flex;
        align-items: center;
        &--space-around {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    &__control-placeholder {
        color: $calendar-header-black;
    }
}

@import 'styles/config';

.FileDropzoneController {
  width: 100%;
  height: 150px;

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__image {
    margin-right: 1rem;
  }

  &:hover {
    color: $gray-700;
  }
}

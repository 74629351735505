@import '../../../styles/config';

.AdminProfiles__empty {
  padding: $spacer * 2 0;
  text-align: center;
  font-size: 1.875rem;
}

.AdminProfiles__title {
  font-size: 2.9375rem;
}

.table_scroll_div4 {
  height: 75vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  thead th {
    position: sticky !important;
    top: 0;
    z-index: 100;
  }
}
